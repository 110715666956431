@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import '~antd/dist/antd.css';

.App {
  text-align: center;
}

.App-header {
  background-color: #eef3f5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

p {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: calc(10px + 2vmin);
  color: #08424e;
  margin: 0;
}

.smaller {
  font-weight: 400;
  font-size: calc(3px + 2vmin);
  margin: 0;
}
